import '../node_modules/bootstrap/dist/css/bootstrap.css';
import './style.css'
import * as THREE from 'three'
import { Fog } from 'three';
import 'animate.css';
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'

// Canvas
const canvas = document.querySelector('canvas.webgl');

// Scene
const scene = new THREE.Scene();

const params = {
    exposure: .45,
    toneMapping: 'ACESFilmic',
};
const toneMappingOptions = {
    ACESFilmic: THREE.ACESFilmicToneMapping,
};


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

})

//Page
var myDiv = document.getElementById("commission");


// Set the initial background position
myDiv.style.backgroundPosition = "center";

// Add a mousemove event listener to the window
window.addEventListener("mousemove", function(event) {
    // Calculate the background position based on the mouse position
    var mouseX = event.clientX;
    var mouseY = event.clientY;

    var backgroundX = (mouseX / sizes.width) * 10 + "%";
    var backgroundY = (mouseY / sizes.height) * 10 + "%";

    // Update the background position
    myDiv.style.backgroundPosition = backgroundX + " " + backgroundY;
});

// Get the image element
var smashlogo = document.querySelector(".smash-logo-games");
var shadowslogo = document.querySelector(".shadows-logo-games");

// Get the video element
var gamevid = document.querySelector("#game-vid");

// Add a load event listener to the video element
gamevid.addEventListener("load", function() {
    gamevid.play();
});

// Add a mouseover event listener to the image element
smashlogo.addEventListener("mouseover", function() {
    SwapVideo('game1');
});
// Add a mouseover event listener to the image element
shadowslogo.addEventListener("mouseover", function() {
    SwapVideo('game2');
});

function SwapVideo(link){

      // Preload the new video source
      var preloadVideo = document.createElement('video');
      preloadVideo.src = "../video/" + link + ".mp4";
      preloadVideo.autoplay = true;
      preloadVideo.muted = true;
      preloadVideo.style.display = 'none';
      document.body.appendChild(preloadVideo);

    document.getElementById("game-vid-mp4").src = "../video/" + link + ".mp4";
    document.getElementById("game-vid-webm").src = "../video/" + link + ".webm";
    gamevid.load();
}


const hamburger = document.querySelector(".hamburger");
const mobileNav = document.querySelector(".mobile-nav");

hamburger.addEventListener("click", () => {
  mobileNav.classList.toggle("show");
  if (mobileNav.classList.contains("show")) {
    mobileNav.classList.remove("animate__fadeOutUp");
    mobileNav.classList.add("animate__fadeInDown");
  } else {
    mobileNav.classList.remove("animate__fadeInDown");
    mobileNav.classList.add("animate__fadeOutUp");
  }
});

const mobileNavLinks = document.querySelectorAll(".mobile-nav a");

mobileNavLinks.forEach((link) => {
  link.addEventListener("click", () => {
    mobileNav.classList.remove("animate__fadeInDown");
    mobileNav.classList.add("animate__fadeOutUp");
  });
});

//END PAGE


/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(70, sizes.width / sizes.height, 0.1, 300);
camera.position.x = 2;
camera.position.y = 1.3;
camera.position.z = 4;
scene.add(camera);

// model
var manager = new THREE.LoadingManager();
manager.onStart = function ( url, itemsLoaded, itemsTotal ) {
    console.log( 'Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
};
manager.onLoad = function ( ) {
    console.log( 'Loading Complete!' );
    document.getElementById("preloader").classList.add("preloader-finish");

};
manager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
    console.log( 'Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
    var percentage = itemsLoaded / itemsTotal;
    // do something with percentage, for example update a progress bar on the page
    document.getElementById("percentageText").innerHTML=""+(percentage * 100).toFixed(0)+"%";
    
    // var progress = document.getElementById("loading-progress");
    // progress.style.width = (percentage * 100) + "%";
};
manager.onError = function ( url ) {
    console.log( 'There was an error loading ' + url );
};

var loader = new THREE.ObjectLoader( manager );
loader.load("./scene.json", function ( object ) {
    object.position.x = 1;
    object.position.y = 0.3;
    object.position.z = 0;
    object.rotation.y = 320;
    scene.add( object );

    // Create a render loop
    function render() {
        requestAnimationFrame(render);
        if(!notMovingMouse){
          object.rotation.y = objRotation;
        }else{
          object.rotation.y += 0.0004;
        }
    }

    render();
});


// Fog
//Create a new instance of the Fog class
const fog = new Fog(0x000000, 1, 22);
scene.fog = fog;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  alpha: true,
  antialias: true
})


//Render Settings
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.autoClear = false;
renderer.clear();
renderer.toneMapping = toneMappingOptions[ params.toneMapping ];
renderer.toneMappingExposure = params.exposure;
renderer.outputEncoding = THREE.sRGBEncoding;
renderer.shadowMap.enabled = true;
renderer.render( scene, camera );


/**
 * Animate
 */
let objRotation = 0;
let timeout;
let notMovingMouse = true;
document.addEventListener("mousemove", function(event) {
  clearTimeout(timeout);
  notMovingMouse = false;
  objRotation += event.movementX * 0.0015;
  timeout = setTimeout(function() {
    notMovingMouse = true;
  }, 1000); // 1000ms = 1s
});

const clock = new THREE.Clock();
const tick = () =>
{
    const elapsedTime = clock.getElapsedTime();

    // Render    
    renderer.render(scene, camera);
    
    // Call tick again on the next frame
    window.requestAnimationFrame(tick);
}
tick();